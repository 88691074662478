import { Ref, ref } from 'vue'
import { HeaderSearchResultEmitType } from '@/Components/the-header-search-result/enums/HeaderSearchResultEmitType'
import { RoomType } from '@/Pages/the-home/enums/Search'

export const showCriteriaSearchModal = ref()

export const handleShowCriteriaSearchModal = (searchCriteria: HeaderSearchResultEmitType): void => {
    showCriteriaSearchModal.value = searchCriteria
}

export const selectedRoomType: Ref<RoomType | string> = ref('')

export const setSelectedRoomType = (isDorm: boolean): void => {
    if (isDorm) {
        selectedRoomType.value = RoomType.PRIVATE
    } else {
        selectedRoomType.value = RoomType.DORM
    }
}